import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import SectionHeader from "./section-header"
import CallToActions from "./call-to-actions"

const Container = styled.div``

const Blurbs = styled.div`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}
  ${props => props.theme.gridGridGutterX()}
  ${props => props.theme.gridGridGutterY()}

  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  @media ${props => props.theme.smallDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }

  > div {
    ${props => props.theme.gridCell(6)}

    @media ${props => props.theme.smallDown} {
      ${props => props.theme.gridCell(12)}
    }
  }
`

const Blurb = styled.article`
  background-color: ${props => props.theme.colorBlack};
  padding: ${rem(45)} ${rem(60)};
  height: 100%;

  @media ${props => props.theme.mediumDown} {
    padding: ${rem(25)} ${rem(20)};
  }

  > div {
    max-width: ${rem(480)};
    height: 100%;
  }
`

const BlurbHeading = styled.div.attrs({ className: `styled-h3` })`
  color: ${props => props.theme.colorTurquoise};
`

const BlurbDescription = styled.div`
  margin-top: ${rem(20)};
  font-size: ${rem(15)};

  > * + * {
    margin-top: ${rem(10)};
  }
`

const CtaBlurbs = ({ data }) => {
  if (!data.published) return null

  return (
    <Container id={data.htmlId}>
      <SectionHeader title={data.sectionHeading} headingLevel="2" />

      {data.content && data.content.length > 0 && (
        <Blurbs>
          {data.content.map((blurb, i) => (
            <div key={i}>
              <Blurb>
                <div>
                  <BlurbHeading as={data.sectionHeading ? `h3` : `h2`}>
                    {blurb.title}
                  </BlurbHeading>

                  {blurb.description && (
                    <BlurbDescription
                      dangerouslySetInnerHTML={{ __html: blurb.description }}
                    />
                  )}

                  <CallToActions cta={blurb.cta} layout="vertical" />
                </div>
              </Blurb>
            </div>
          ))}
        </Blurbs>
      )}
    </Container>
  )
}

CtaBlurbs.propTypes = {
  data: PropTypes.object,
}

export default CtaBlurbs
