// const GOOGLE_RECAPTCHA_SITE_KEY = process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY

import { Form, Formik } from "formik"
import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import * as Yup from "yup"
import { useApexRefSignUpForm } from "../hooks/apex-ref-hooks"
import { StoreContext } from "../store"
import { rem } from "polished"
// import {
//   GoogleReCaptchaProvider,
//   GoogleReCaptcha,
// } from "react-google-recaptcha-v3"

import { findGetParameter } from "../utils"
import Button from "./button"
import Field from "./form/field"
import { hasWindow } from "../utils"

const Row = styled.div`
  margin-top: 1em;
  max-width: ${rem(600)};
  margin-left: auto;
  margin-right: auto;

  label {
    color: white !important;

    a {
      font-size: ${rem(15)};
      text-decoration: underline;
      text-decoration-color: #888;
      opacity: 0.5;
    }
  }

  input {
    color: black !important;
  }

  button {
    margin-top: 1em;
  }
`
const FormHeading = styled.h2.attrs({ className: `styled-h2` })`
  padding: ${rem(20)} ${rem(20)} 0;
  text-align: center;

  em {
    color: ${props => props.theme.colorYellow};
    font-style: normal;
  }
`

const FormDescription = styled.p.attrs({ className: `styled-p-large` })`
  padding: ${rem(10)} 0 ${rem(20)};
  text-align: center;
  max-width: 55ch;
  margin-left: auto;
  margin-right: auto;
`

const Message = styled.div`
  padding: ${rem(20)};
  text-align: center;
  > div {
    margin-bottom: ${rem(20)};
  }
`

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(`Please enter your email.`),
  terms: Yup.bool().oneOf(
    [true],
    `You must agree to the terms to be able to sign up.`
  ),
  referralCode: Yup.string(),
  // recaptcha: Yup.string().required(),
})

const SignupForm = ({
  signedUp,
  formHeading,
  formDescription,
  emailLabel,
  termsLabel,
  termsUrl,
  buttonLabel,
  buttonLabelSignedUp,
  confirmOld,
  confirmNew,
  campaign,
}) => {
  const {
    isLoading,
    errors,
    messages,
    formikHandleSubmit,
    setReferralCodeCookie,
    referralCode,
  } = useApexRefSignUpForm()
  const [isSubmitted, setIsSubmitted] = useState(false)
  // const [recaptcha, setRecaptcha] = useState(false)
  const store = useContext(StoreContext)

  if (!hasWindow) {
    return null
  }
  if (store.status === `loggedIn`) {
    return null
  }

  if (isLoading) {
    return <div className="-loading">Loading… Please hang on…</div>
  }

  if (!referralCode && findGetParameter(`referral`)) {
    setReferralCodeCookie(findGetParameter(`referral`))
  }

  const initialValues = {
    email: ``,
    referralCode: referralCode ? referralCode : ``,
    terms: signedUp ? true : false,
    campaign: campaign,
    // recaptcha: recaptcha,
  }

  if (!isSubmitted) {
    return (
      // <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            setIsSubmitted(true)

            return formikHandleSubmit(values)
          }}
        >
          {() => (
            <Form id={`warsupport-form`}>
              <FormHeading dangerouslySetInnerHTML={{ __html: formHeading }} />
              <FormDescription
                dangerouslySetInnerHTML={{ __html: formDescription }}
              />
              {errors && errors.length > 0 && (
                <pre>{JSON.stringify(errors, null, 2)}</pre>
              )}
              <Row className={`is-vhidden`}>
                <Field
                  name="referralCode"
                  label="Referral code"
                  type="text"
                  disabled
                />
              </Row>
              <Row>
                <Field name="email" label={emailLabel} type="email" />
              </Row>
              <Row>
                <Field
                  name="terms"
                  label={`<a href="${termsUrl}" target="_blank">${termsLabel}</a>`}
                  type="checkbox"
                  className={signedUp ? `is-vhidden` : ``}
                />
              </Row>
              <Row style={{ textAlign: `center` }}>
                <Button
                  data-track-click
                  data-track-click-ga-category="War Support"
                  data-track-click-ga-action="Submit Form"
                  data-track-click-ga-label={
                    signedUp ? buttonLabelSignedUp : buttonLabel
                  }
                  type="submit"
                  large
                  color={`green`}
                >
                  {signedUp ? buttonLabelSignedUp : buttonLabel}
                </Button>
              </Row>
              {/* <GoogleReCaptcha onVerify={token => setRecaptcha(token)} /> */}
            </Form>
          )}
        </Formik>
      // </GoogleReCaptchaProvider>
    )
  }

  if (messages && messages.includes(`record_created`)) {
    return (
      <Message>
        <div dangerouslySetInnerHTML={{ __html: confirmNew }} />

        <Button
          onClick={() => {
            setIsSubmitted(false)

            if (store.status !== `anonymous`) {
              store.setStatus(`anonymous`)
            }
          }}
        >
          Reload the form
        </Button>
      </Message>
    )
  }

  if (messages && messages.includes(`auth_token_sent`)) {
    return (
      <Message>
        <div
          className={`styled-p-large`}
          dangerouslySetInnerHTML={{ __html: confirmOld }}
        />
        <Button
          onClick={() => {
            setIsSubmitted(false)

            if (store.status !== `anonymous`) {
              store.setStatus(`anonymous`)
            }
          }}
        >
          Reload the form
        </Button>
      </Message>
    )
  }

  return null
}

SignupForm.propTypes = {
  formHeading: PropTypes.string,
  formDescription: PropTypes.string,
  emailLabel: PropTypes.string,
  termsLabel: PropTypes.string,
  termsUrl: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLabelSignedUp: PropTypes.string,
  confirmOld: PropTypes.string,
  confirmNew: PropTypes.string,
  campaign: PropTypes.string,
  signedUp: PropTypes.bool,
}

export default SignupForm
