import React from "react"
import PropTypes from "prop-types"

import HeroIntroduction from "./hero-introduction"

const BigBlurb = ({ data }) => {
  return <HeroIntroduction data={data} fullHeight={false} />
}

BigBlurb.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BigBlurb
