import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { useCookies } from "react-cookie"
import { StoreContext } from "../store"

import SignupForm from "./signup-form"
import styled from "styled-components"
import { rem } from "polished"
import Button from "./button"

const LogBackIn = styled.section`
  padding-top: ${rem(70)};
  margin-bottom: ${rem(-40)};
  text-align: center;
`
const Heading = styled.h5.attrs({ className: `styled-h3` })`
  padding-bottom: ${rem(10)};
`

const Info = styled.p`
  padding-bottom: ${rem(10)};
`

const ReclamationEffortSignup = ({ data }) => {
  const [signedUp, setSignedup] = useState(false)
  const [cookies] = useCookies([`apex-ref-token`])
  const store = useContext(StoreContext)

  const showLogBackIn = store.status === `anonymous`

  const handleSignupButton = event => {
    event.preventDefault()

    return setSignedup(!signedUp)
  }

  if (!cookies[`apex-ref-token`]) {
    return (
      <React.Fragment>
        <SignupForm {...data} signedUp={signedUp} />

        {showLogBackIn && (
          <LogBackIn>
            <Heading>
              {!signedUp ? `Already signed up?` : `Haven't signed up yet?`}
            </Heading>
            {!signedUp && (
              <Info>
                Sign in again to see your referral status and rewards.
              </Info>
            )}
            <Button
              color={`black`}
              onClick={event => handleSignupButton(event)}
            >
              {!signedUp ? `Sign in` : `Show sign up form`}
            </Button>
          </LogBackIn>
        )}
      </React.Fragment>
    )
  }

  return null
}

ReclamationEffortSignup.propTypes = {
  data: PropTypes.object,
}

export default ReclamationEffortSignup
