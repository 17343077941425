import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import Header from "./header"
import SectionHeader from "./section-header"
import CallToActions from "./call-to-actions"

const Text = styled.div`
  padding: ${rem(40)} ${rem(20)};
  padding-right: 10%;
  flex-basis: 45%;
  text-align: ${props => props.textAlignment};

  @media ${props => props.theme.mediumDown} {
    padding: ${rem(20)};
    text-align: center;
  }
`

const HeaderWrap = styled.div`
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`

const ImagesContainer = styled.div`
  flex-grow: 1;
  align-self: stretch;

  @media ${props => props.theme.mediumUp} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ${props => props.theme.mediumDown} {
    position: relative;
    margin-top: ${rem(20)};
  }
`

const Image = styled.figure`
  flex-basis: 100%;
  align-self: center;
  margin: ${rem(20)} 0;

  @media ${props => props.theme.mediumDown} {
    padding: 0 0 ${rem(20)};
    margin: 0 auto;
  }

  > * {
    height: 90%;
    width: auto;
    max-height: ${rem(900)};

    @media ${props => props.theme.mediumDown} {
      max-height: 100vh;

      img {
        object-position: center center !important;
      }
    }
  }
`

const BackgroundImage = styled.figure`
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  clip-path: polygon(${rem(110)} 0, 100% 0, 100% 100%, 0 100%);

  @media ${props => props.theme.mediumDown} {
    width: 100%;
    height: 100%;
    clip-path: polygon(0 ${rem(60)}, 100% 0, 100% 100%, 0 100%);
  }

  > * {
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.fullHeight === false &&
    css`
      @media ${props.theme.largeUp} {
        right: ${rem(20)};
      }
    `}

  ${props =>
    props.layout === `right` &&
    css`
      right: auto;
      left: 0;
      clip-path: polygon(
        0 0,
        calc(100% - ${rem(110)}) 0,
        100% 100%,
        ${rem(110)} 100%
      );

      ${props =>
        props.fullHeight === false &&
        css`
          @media ${props.theme.largeUp} {
            left: ${rem(20)};
          }
        `}
    `}
`

const Heading = styled.h2.attrs({ className: `styled-h1` })`
  margin-bottom: ${rem(20)};

  em {
    color: ${props => props.theme.colorYellow};
    font-style: normal;
  }
`

const Description = styled.div.attrs({ className: `styled-p-large` })`
  max-width: 40ch;

  @media ${props => props.theme.mediumDown} {
    padding-bottom: ${rem(20)};
    margin-left: auto;
    margin-right: auto;
  }
`

const Inner = styled.section`
  ${props => props.theme.gridContainer()}

  padding: 0;
  flex-grow: 1;

  @media ${props => props.theme.mediumUp} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ${props => props.theme.mediumDown} {
    padding: 0;
    padding-top: ${rem(100)};
  }

  ${props =>
    props.layout === `right` &&
    css`
      flex-direction: row-reverse;
    `}

  ${props =>
    props.fullHeight === false &&
    css`
      position: relative;
    `}

  ${props =>
    !props.hasImages &&
    css`
      @media ${props => props.theme.mediumUp} {
        justify-content: center;

        & > div {
          flex-basis: 60%;

          .styled-p-large {
            max-width: 100%;
          }
        }
      }
    `}
`

const Container = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-size: 8px 8px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7b3BhY2l0eTowLjE7fQoJLnN0MXtmaWxsOiNGRkZGRkY7fQo8L3N0eWxlPgo8ZyBjbGFzcz0ic3QwIj4KCTxjaXJjbGUgY2xhc3M9InN0MSIgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41Ii8+CjwvZz4KPGcgY2xhc3M9InN0MCI+Cgk8Y2lyY2xlIGNsYXNzPSJzdDEiIGN4PSI5LjUiIGN5PSI5LjUiIHI9IjIuNSIvPgo8L2c+Cjwvc3ZnPgo=");

  @media ${props => props.theme.mediumDown} {
    min-height: 0;
  }

  ${props =>
    props.displayHeader &&
    css`
      padding-top: ${rem(100)};

      @media ${props => props.theme.xlargeDown} {
        padding-top: ${rem(80)};
      }

      @media ${props => props.theme.mediumDown} {
        padding-top: 0;
      }

      ${Image} {
        padding-top: 0;
      }
    `}

  ${props =>
    props.fullHeight === false &&
    css`
      min-height: 0;
    `}

  ${props =>
    !props.hasImage &&
    props.fullHeight &&
    css`
      @media ${props => props.theme.mediumDown} {
        padding-bottom: 70vh;
      }
    `}
`

const HeroIntroduction = ({ data, displayHeader, fullHeight }) => {
  if (!data.published) return null

  const hasImage = !!(data.image && data.image.localFile)
  const hasImages =
    (data.image && data.image.localFile) ||
    (data.backgroundImage && data.backgroundImage.localFile) ||
    false

  return (
    <Container
      id={data.htmlId}
      displayHeader={displayHeader}
      fullHeight={fullHeight}
      hasImage={hasImage}
    >
      {displayHeader && (
        <HeaderWrap>
          <Header />
        </HeaderWrap>
      )}

      {!displayHeader && (
        <SectionHeader title={data.sectionHeading} headingLevel="2" />
      )}

      <Inner
        fullHeight={fullHeight}
        hasImages={hasImages}
        layout={data.textPosition || `left`}
      >
        <Text textAlignment={data.textAlignment || `left`}>
          {data.title && (
            <Heading dangerouslySetInnerHTML={{ __html: data.title }} />
          )}

          {data.description && (
            <Description
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}

          <CallToActions cta={data.cta} layout="vertical" />
        </Text>

        {hasImages && (
          <ImagesContainer>
            {hasImage && (
              <Image>
                <Img
                  fluid={data.image.localFile.childImageSharp.fluid}
                  alt={`${data.titleFirstLine} ${data.titleSecondLine}`}
                  objectFit="contain"
                  objectPosition={
                    data.textPosition === `left`
                      ? `center left`
                      : `center center`
                  }
                />
              </Image>
            )}

            {data.backgroundImage && data.backgroundImage.localFile && (
              <BackgroundImage
                fullHeight={fullHeight}
                layout={data.textPosition || `left`}
              >
                <Img
                  fluid={data.backgroundImage.localFile.childImageSharp.fluid}
                  alt={`${data.titleFirstLine} ${data.titleSecondLine}`}
                  role="presentation"
                />
              </BackgroundImage>
            )}
          </ImagesContainer>
        )}
      </Inner>
    </Container>
  )
}

HeroIntroduction.propTypes = {
  data: PropTypes.object.isRequired,
  displayHeader: PropTypes.bool,
  fullHeight: PropTypes.bool,
}

export default HeroIntroduction
