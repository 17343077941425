import React, { useContext, useEffect } from "react"
import { useApexRef } from "../hooks/apex-ref-hooks"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { StoreContext } from "../store"
import MetaWp from "../components/meta-wp"

import Header from "../components/header"
import BigBlurb from "../components/big-blurb"
import CtaBlurbs from "../components/cta-blurbs"
import ReclamationEffortSignup from "../components/reclamation-effort-signup"
import ReclamationEffortProfile from "../components/reclamation-effort-profile"
import ReclamationEffortGoalsAndRewards from "../components/reclamation-effort-goals-and-rewards"

const Container = styled.div`
  background: radial-gradient(rgba(0, 0, 0, 20%) 5%, rgba(0, 0, 0, 92%) 92%);
  position: relative;
`

const Inner = styled.section`
  ${props => props.theme.gridContainer()}

  padding-top: ${rem(70)};
  padding-bottom: ${rem(90)};

  .-loading {
    padding: ${rem(20)};
    text-align: center;
  }
`

const Goals = styled.section`
  ${props => props.theme.gridContainer()}

  padding-top: ${rem(10)};
  padding-bottom: ${rem(70)};
`

const Background = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-image: radial-gradient(
    circle farthest-corner at 53.9% 53.1%,
    rgb(54, 49, 49) 0%,
    rgb(18, 18, 18) 100.2%
  );
  opacity: 0.5;

  @media screen and (-webkit-min-device-pixel-ratio: 2),
    screen and (min--moz-device-pixel-ratio: 2),
    screen and (-o-min-device-pixel-ratio: 2/1),
    screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    opacity: 0.8;
  }
`

const ReclaimationEffortTemplate = ({ data }) => {
  const { referralData } = useApexRef()
  const store = useContext(StoreContext)

  useEffect(() => {
    if (store.showBanner) {
      return store.setShowBanner(false)
    }

    return _ => {
      store.setShowBanner(true)
    }
  }, [store])

  const { wp, metaWp } = data
  const {
    title,
    acf: {
      title: acfTitle,
      loggedInTitle,
      description,
      loggedInDescription,
      formHeading,
      formDescription,
      termsUrl,
      termsLabel,
      newsletterUrl,
      newsletterLabel,
      emailLabel,
      signupButton,
      confirmOld,
      confirmNew,
      image,
      background,
      loggedInBackground,
      loggedInImage,

      referralInfoBackground,
      referralInfoLinkDescription,
      referralInfoRecruitmentTitle,
      referralInfoRecruitmentDescription,
      goalsContent,
      campaignInfo,
    },
  } = wp

  const signupStrings = {
    formHeading: formHeading,
    formDescription: formDescription,
    emailLabel: emailLabel,
    termsLabel: termsLabel,
    termsUrl: termsUrl,
    newsletterLabel: newsletterLabel,
    newsletterUrl: newsletterUrl,
    buttonLabel: signupButton,
    buttonLabelSignedUp: `Sign in`,
    confirmOld: confirmOld,
    confirmNew: confirmNew,
  }

  const referralInfoStrings = {
    background: referralInfoBackground,
    description: referralInfoLinkDescription,
    countTitle: referralInfoRecruitmentTitle,
    countDescription: referralInfoRecruitmentDescription,
  }

  return (
    <React.Fragment>
      <MetaWp wp={metaWp} />

      <h1 className="is-vhidden">{title}</h1>

      <Header />

      <BigBlurb
        data={{
          published: true,
          title: referralData ? loggedInTitle : acfTitle,
          description: referralData ? loggedInDescription : description,
          backgroundImage: referralData ? loggedInBackground : background,
          image: referralData ? loggedInImage : image,
        }}
      />

      <Container>
        <Inner>
          <ReclamationEffortSignup data={signupStrings} />
          <ReclamationEffortProfile data={referralInfoStrings} />
        </Inner>

        <Goals>
          <ReclamationEffortGoalsAndRewards
            currentReferrals={referralData && referralData.signup.referrals}
            data={goalsContent}
          />
        </Goals>

        <CtaBlurbs data={campaignInfo} />

        <Background />
      </Container>
    </React.Fragment>
  )
}

ReclaimationEffortTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ReclaimationEffortTemplate

export const pageQuery = graphql`
  query($id: String!) {
    ...MetaWpPage

    wp: wordpressPage(id: { eq: $id }) {
      ...ReclamationEffortFragment
    }
  }
`
