import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

const Container = styled.div`
  margin-top: ${rem(40)};
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.colorBlack};
  background-size: 8px 8px;
  background-image: -webkit-repeating-radial-gradient(
    center center,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.05) 1.5px,
    transparent 1.5px,
    transparent 100%
  );
  /* TODO: replace ^ with base64 svg tile same as header  */

  @media ${props => props.theme.mediumDown} {
    flex-direction: column;
  }
`

const Description = styled.div.attrs({ className: `styled-p-large` })`
  flex-grow: 1;
  padding: ${rem(40)};
`

const Count = styled.div`
  background: ${props => props.theme.colorOrange};
  text-align: center;
  color: ${props => props.theme.colorBlack};
  padding-left: ${rem(40)};
  padding-right: ${rem(40)};
  width: 100%;
  clip-path: polygon(0% ${rem(20)}, 100% 0%, 100% 100%, 0% 100%);
  padding-top: ${rem(20)};

  @media ${props => props.theme.mediumUp} {
    width: auto;
    min-width: ${rem(240)};
    padding-top: 0;
    clip-path: polygon(${rem(20)} 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`

const CountTitle = styled.h4.attrs({ className: `styled-h3` })`
  padding: ${rem(20)};
`
const CountNumber = styled.div.attrs({ className: `styled-h1` })`
  padding: ${rem(20)};
  padding-top: 0;
`

const ReferralCount = ({ countDescription, countTitle, count }) => {
  return (
    <Container>
      <Inner>
        <Description dangerouslySetInnerHTML={{ __html: countDescription }} />

        <Count>
          <CountTitle dangerouslySetInnerHTML={{ __html: countTitle }} />
          <CountNumber>{count}</CountNumber>
        </Count>
      </Inner>
    </Container>
  )
}

ReferralCount.propTypes = {
  countDescription: PropTypes.string,
  countTitle: PropTypes.string,
  count: PropTypes.number,
}

export default ReferralCount
