import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useApexRef } from "../hooks/apex-ref-hooks"
import styled from "styled-components"
import { rem } from "polished"
import copy from "copy-to-clipboard"

import { StoreContext } from "../store"
import { findGetParameter, hasWindow } from "../utils"

import Button from "./button"
import ReferralCount from "./referral-count"

const Container = styled.div`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};
  text-align: center;
`

const ReferralUrlHeading = styled.h2.attrs({ className: `styled-h2` })`
  padding: ${rem(20)} ${rem(20)} 0;
  text-align: center;

  em {
    color: ${props => props.theme.colorYellow};
    font-style: normal;
  }
`

const ReferralUrlInput = styled.div`
  width: 100%;
  display: block;
  background: ${props => props.theme.colorWhite};
  color: ${props => props.theme.colorBlack};
  border: solid ${rem(3)} ${props => props.theme.colorWhite};
  border-radius: ${rem(6)};
  font-size: ${rem(18)};
  margin-bottom: ${rem(20)};
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.4);
  line-height: 1.333;
  max-width: ${rem(700)};
  margin-left: auto;
  margin-right: auto;

  > input {
    display: block;
    width: 100%;
    padding: ${rem(20)};
  }
`

const Description = styled.div.attrs({ className: `styled-p-large` })`
  margin-bottom: ${rem(20)};
  margin-left: auto;
  margin-right: auto;
  max-width: ${rem(600)};
`

const ReclamationEffortProfile = ({
  data: { countTitle, countDescription, description },
}) => {
  const { isLoading, referralData, authenticate } = useApexRef()
  const store = useContext(StoreContext)

  const handleClipboard = event => {
    event.preventDefault()
    event.persist()

    const textToCopy = `${window.location.href}?referral=${referralData.signup.referral_code}`
    copy(textToCopy)

    const button = event.currentTarget
    const temp = button.innerHTML
    button.innerHTML = `<span><span>Copied!</span></span>`
    setTimeout(() => {
      button.innerHTML = temp
    }, 1000)
  }

  if (!hasWindow) {
    return null
  }
  if (isLoading) {
    return <div>Loading...</div>
  }
  if (!referralData && findGetParameter(`magic_link`)) {
    if (window.ga) {
      window.ga(`ga_secondextinction.send`, `event`, `War Support`, `Signed in`)
    }
    authenticate(findGetParameter(`magic_link`))
  }

  if (referralData && store.status === `loggedIn`) {
    if (window.recaptcha) {
      if (document.getElementById(`google-recaptcha-v3`)) {
        document
          .getElementById(`google-recaptcha-v3`)
          .parentElement.removeChild(
            document.getElementById(`google-recaptcha-v3`)
          )
      }

      if (document.querySelector(`.grecaptcha-badge`)) {
        document
          .querySelector(`.grecaptcha-badge`)
          .parentElement.removeChild(
            document.querySelector(`.grecaptcha-badge`)
          )
      }
    }

    return (
      <Container>
        <div>
          <ReferralUrlHeading>
            Share your
            <br />
            <em>recruitment link</em>
          </ReferralUrlHeading>

          <Description dangerouslySetInnerHTML={{ __html: description }} />

          <ReferralUrlInput>
            <input
              name="referralUrl"
              type="text"
              value={`${window.location.href}?referral=${referralData.signup.referral_code}`}
              disabled
            />
          </ReferralUrlInput>

          <Button onClick={event => handleClipboard(event)}>
            Click to copy your link
          </Button>
        </div>

        <ReferralCount
          countDescription={countDescription}
          countTitle={countTitle}
          count={referralData.signup.referrals}
        />
      </Container>
    )
  }

  return null
}

ReclamationEffortProfile.propTypes = {
  data: PropTypes.object,
}

export default ReclamationEffortProfile
